import React, { FC, ReactNode } from 'react'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles'
import red from '@mui/material/colors/red'
import grey from '@mui/material/colors/grey'

const primaryColor = '#282c34'
const secondaryColor = red[600]

const theme = createTheme({
  palette: {
    mode: 'dark',
    secondary: {
      main: secondaryColor,
    },
    primary: {
      main: primaryColor,
    },
    text: {
      primary: '#e6e5e8',
      secondary: '#adb0bb',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: '0.875rem',
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontWeight: 400,
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
        h2: ({ theme }) => ({
          fontSize: '1.40625rem',
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontWeight: 500,
          lineHeight: 1.2,
          letterSpacing: '-0.24px',
          [theme.breakpoints.up('sm')]: {
            fontSize: '1.6667rem',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '1.6667rem',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.875rem',
          },
        }),
        h3: ({ theme }) => ({
          fontSize: '1.25rem',
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontWeight: 500,
          lineHeight: 1.167,
          letterSpacing: '-0.06px',
          [theme.breakpoints.up('sm')]: {
            fontSize: '1.2853rem',
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '1.4996rem',
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: '1.4996rem',
          },
        }),
        h5: {
          fontSize: '16px',
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontWeight: 500,
          lineHeight: 1.334,
          letterSpacing: '-0.05px',
        },
        h6: {
          fontSize: '14px',
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.54)',
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.26)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(81, 81, 81, 1)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: 'rgba(255, 255, 255, 0.54)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'rgba(255, 255, 255, 0.54)',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: secondaryColor,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: secondaryColor,
            },
          },
        },
        notchedOutline: {
          borderColor: 'rgba(255, 255, 255, 0.23)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '0 0 1px 0 rgba(0,0,0,0.70), 0 3px 4px -2px rgba(0,0,0,0.50)',
        },
        elevation8: {
          boxShadow:
            '0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)',
        },
        root: {
          color: '#e6e5e8',
          backgroundColor: primaryColor,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          color: primaryColor,
          backgroundColor: grey[600],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          border: 'none',
          height: '1px',
          margin: 0,
          flexShrink: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
        },
      },
    },
  },
})

export interface ThemeProviderProps {
  readonly children: ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
}: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
)

export default ThemeProvider
