import React, { FC, ComponentType } from 'react'
import { useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom'
import { stringify } from 'query-string'
import type { RootState } from 'store'
import type { SessionInitialization } from 'containers/SessionProvider/types'
import FullProgress from 'components/FullProgress'

const isProgress = (initialization: SessionInitialization) =>
  initialization === 'uninitialized' || initialization === 'initializing'

const PrivateRoute: FC<RouteProps> = ({
  component,
  render,
  ...props
}: RouteProps) => {
  const { initialization, user } = useSelector(
    ({ session }: RootState) => session,
  )
  return (
    <Route
      {...props}
      render={(p) => {
        if (initialization === 'initialized' && user) {
          const Component = component as ComponentType<RouteComponentProps>
          return render ? render(p) : <Component {...p} />
        }
        if (isProgress(initialization)) {
          return <FullProgress />
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              search: `?${stringify({ from: p.location.pathname })}`,
              state: { from: p.location },
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
