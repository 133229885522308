import React, { FC, Fragment } from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import useStyles from './useStyles'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'

export interface UserProfileProps {
  readonly user: UserSession
}

const UserProfile: FC<UserProfileProps> = ({ user }: UserProfileProps) => {
  const classes = useStyles()
  return (
    <Fragment>
      <Box p={2}>
        <Box display="flex" justifyContent="center">
          <Link component={RouterLink} to="/account">
            <Avatar
              className={classes.avatar}
              src={user.imageUri || undefined}
            />
          </Link>
        </Box>
        <Box mt={2} textAlign="center">
          <Link
            component={RouterLink}
            to="/account"
            variant="h5"
            underline="none"
            color="textPrimary"
          >
            {user.email}
          </Link>
        </Box>
      </Box>
      <Divider />
    </Fragment>
  )
}

export default UserProfile
