import React, { FC, Fragment } from 'react'
import AppBar from '@mui/material/AppBar'
import useStyles from './useStyles'
import Toolbar from '@mui/material/Toolbar'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import useSelector from './useSelector'
import { useDispatch } from 'react-redux'
import { togglePopover, resetError, loggingOutBegin } from './actions'
import { useInjectReducer } from 'hooks/useInjectReducer'
import reducer from './reducer'
import { useSelector as useRootSelector } from 'react-redux'
import { RootState } from 'store'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Snackbar from 'components/Snackbar'
import ProgressOverlay from 'components/ProgressOverlay'
import saga from './saga'
import { useInjectSaga } from 'hooks/useInjectSaga'
import LinearProgress from '@mui/material/LinearProgress'

interface MainHeaderProps {
  readonly onNavigationClick?: () => void
}

const MainHeader: FC<MainHeaderProps> = ({
  onNavigationClick,
}: MainHeaderProps) => {
  useInjectSaga({ key: 'mainHeader', saga })
  useInjectReducer({ key: 'mainHeader', reducer })
  const classes = useStyles()
  const dispatch = useDispatch()
  const { popoverAnchorEl, errorMessage, loading } = useSelector()
  const user = useRootSelector(({ session }: RootState) => session.user)
  const open = Boolean(popoverAnchorEl)
  const id = open ? 'user-popover' : undefined
  return (
    <Fragment>
      <AppBar elevation={4} position="fixed" classes={{ root: classes.appBar }}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <IconButton
            onClick={onNavigationClick}
            color="inherit"
            disableTouchRipple
            disableFocusRipple
          >
            <MenuIcon />
          </IconButton>
          <Link component={RouterLink} to="/" className={classes.logo}>
            <img src="/android-chrome-192x192.png" alt="ASD - Bakrie" />
          </Link>
          <Box flexGrow={1} ml={2} />
          <Box ml={2}>
            {user && (
              <Fragment>
                <Box
                  aria-describedby={id}
                  onClick={(
                    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
                  ) => dispatch(togglePopover(event.currentTarget))}
                  className={classes.buttonPopover}
                >
                  <ButtonBase>
                    <Avatar
                      classes={{
                        root: classes.avatar,
                      }}
                      src={user.imageUri || undefined}
                    />
                    <Typography variant="h6" color="inherit">
                      {user.email}
                    </Typography>
                  </ButtonBase>
                </Box>
                <Menu
                  id={id}
                  open={open}
                  anchorEl={popoverAnchorEl}
                  onClose={() => dispatch(togglePopover(null))}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  classes={{ paper: classes.menuPaper }}
                >
                  <MenuItem
                    component={RouterLink}
                    to="/account"
                    className={classes.menuItem}
                  >
                    Account
                  </MenuItem>
                  <ProgressOverlay visible={loading} fullWidth>
                    <MenuItem
                      component="a"
                      onClick={() => dispatch(loggingOutBegin())}
                      className={classes.menuItem}
                    >
                      Logout
                    </MenuItem>
                  </ProgressOverlay>
                </Menu>
              </Fragment>
            )}
          </Box>
        </Toolbar>
        {loading && (
          <LinearProgress
            color="secondary"
            className={classes.linearProgress}
          />
        )}
      </AppBar>
      <Snackbar
        severity="error"
        open={!!errorMessage}
        onClose={() => dispatch(resetError())}
      />
    </Fragment>
  )
}

export default MainHeader
