import { makeStyles } from '@mui/styles'

interface Props {
  readonly fullWidth?: boolean
}

export default makeStyles(() => ({
  root: ({ fullWidth }: Props) => ({
    position: 'relative',
    display: fullWidth ? undefined : 'inline-block',
  }),
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
