import {
  SET_SESSION_ACTION,
  REMOVE_SESSION_ACTION,
  INIT_SESSION_ACTION,
} from './constants'
import {
  SetSessionAction,
  RemoveSessionAction,
  InitSessionAction,
} from './types'

export interface InitSessionActionCreator {
  (): InitSessionAction
}

export interface SetSessionActionCreator {
  (user: UserSession, token?: string): SetSessionAction
}

export interface RemoveSessionActionCreator {
  (): RemoveSessionAction
}

export const initSessionAction: InitSessionActionCreator = () => ({
  type: INIT_SESSION_ACTION,
})

export const setSessionAction: SetSessionActionCreator = (user, token) => ({
  type: SET_SESSION_ACTION,
  payload: { user, token },
})

export const removeSessionAction: RemoveSessionActionCreator = () => ({
  type: REMOVE_SESSION_ACTION,
})
