import { makeStyles } from '@mui/styles'
import type { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  avatar: {
    width: theme.spacing(8),
    cursor: 'pointer',
    height: theme.spacing(8),
  },
}))
