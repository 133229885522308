import FullscreenPageWrapper from 'containers/FullscreenPageWrapper'
import React, { FC, ComponentType, ReactNode } from 'react'
import { RouteProps, RouteComponentProps } from 'react-router-dom'
import Route from './index'

const PrivateRoute: FC<RouteProps> = ({
  component,
  render,
  ...props
}: RouteProps) => (
  <Route
    {...props}
    render={(p) => {
      const Component = component as ComponentType<RouteComponentProps>
      const child = render ? (
        (render(p) as NonNullable<ReactNode>)
      ) : (
        <Component {...p} />
      )
      return <FullscreenPageWrapper>{child}</FullscreenPageWrapper>
    }}
  />
)

export default PrivateRoute
