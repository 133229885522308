import React, { FC } from 'react'
import { Switch } from 'react-router-dom'
import MainPrivateRoute from 'containers/PrivateRoute/Main'
import FullscreenPrivateRoute from 'containers/PrivateRoute/Fullscreen'
import NoAuthRoute from 'containers/NoAuthRoute'
import HomePage from 'containers/HomePage/Loadable'
import LoginPage from 'containers/LoginPage/Loadable'
import CourierListPage from 'containers/CourierListPage/Loadable'
import OrderListPage from 'containers/OrderListPage/Loadable'
import ProductList from 'containers/ProductList/Loadable'
import PaymentListPage from 'containers/PaymentListPage/Loadable'
import UserVerification from 'containers/UserVerification/Loadable'
import UserVerificationDetail from 'containers/UserVerificationDetail/Loadable'
import OrderDetailPage from 'containers/OrderDetailPage/Loadable'
import ProductDetail from 'containers/ProductDetail/Loadable'
import PaymentCreate from 'containers/PaymentCreate/Loadable'
import ProductImageUpload from 'containers/ProductImageUpload/Loadable'
import UsersList from 'containers/UsersList/Loadable'
import UserDetail from 'containers/UserDetail/Loadable'
import UserCreate from 'containers/UserCreate/Loadable'
import ForgotPassword from 'containers/ForgotPasswordPage/Loadable'
import UpdatePassword from 'containers/UpdatePassword/Loadable'
import UpdateProfilePage from 'containers/UpdateProfilePage/Loadable'
import ProductCreate from 'containers/ProductCreate/Loadable'
import NewsListPage from 'containers/NewsListPage/Loadable'
import GalleryListPage from 'containers/GalleryListPage/Loadable'
import GalleryDetail from 'containers/GalleryDetail/Loadable'
import PackagingListPage from 'containers/PackagingListPage/Loadable'
import GalleryCreate from 'containers/GalleryCreate/Loadable'
import NewsDetail from 'containers/NewsDetail/Loadable'
import PackagingCreate from 'containers/PackagingCreate/Loadable'
import GalleryImageUpload from 'containers/GalleryImageUpload/Loadable'
import NewsCreate from 'containers/NewsCreate/Loadable'
import OriginAddress from 'containers/OriginAddress/Loadable'
import OriginAddressUpdate from 'containers/OriginAddressUpdate/Loadable'
import StatisticPage from 'containers/StatisticPage/Loadable'
import OrderStatistic from 'containers/OrderStatistic/Loadable'
import UserStatistic from 'containers/UserStatistic/Loadable'
import ProductStatistic from 'containers/ProductStatistic/Loadable'

const Router: FC = () => (
  <Switch>
    <MainPrivateRoute exact path="/" component={HomePage} />
    <MainPrivateRoute exact path="/courier" component={CourierListPage} />
    <MainPrivateRoute exact path="/order/:id" component={OrderDetailPage} />
    <MainPrivateRoute exact path="/order" component={OrderListPage} />
    <MainPrivateRoute
      exact
      path="/user-verification"
      component={UserVerification}
    />
    <MainPrivateRoute exact path="/packaging" component={PackagingListPage} />
    <MainPrivateRoute
      exact
      path="/packaging/create"
      component={PackagingCreate}
    />
    <MainPrivateRoute
      exact
      path="/packaging/:id/update"
      component={PackagingCreate}
    />
    <MainPrivateRoute
      exact
      path="/user-verification/:id/detail"
      component={UserVerificationDetail}
    />
    <MainPrivateRoute
      exact
      path="/product/:id/detail"
      component={ProductDetail}
    />
    <MainPrivateRoute exact path="/gallery" component={GalleryListPage} />
    <MainPrivateRoute
      exact
      path="/gallery/:id/detail"
      component={GalleryDetail}
    />
    <MainPrivateRoute exact path="/gallery/create" component={GalleryCreate} />
    <MainPrivateRoute
      exact
      path="/gallery/:id/update"
      component={GalleryCreate}
    />
    <MainPrivateRoute
      exact
      path="/gallery-image/:id/create"
      component={GalleryImageUpload}
    />
    <MainPrivateRoute exact path="/product" component={ProductList} />
    <MainPrivateRoute
      exact
      path="/payment-method"
      component={PaymentListPage}
    />
    <MainPrivateRoute
      exact
      path="/payment-method/:id/update"
      component={PaymentCreate}
    />
    <MainPrivateRoute
      exact
      path="/payment-method/create"
      component={PaymentCreate}
    />
    <MainPrivateRoute
      exact
      path="/product-images/:id/create"
      component={ProductImageUpload}
    />
    <MainPrivateRoute
      exact
      path="/update-password"
      component={UpdatePassword}
    />
    <MainPrivateRoute
      exact
      path="/update-profile"
      component={UpdateProfilePage}
    />
    <MainPrivateRoute exact path="/news" component={NewsListPage} />
    <MainPrivateRoute exact path="/news/:id/detail" component={NewsDetail} />
    <MainPrivateRoute exact path="/news/:id/update" component={NewsCreate} />
    <MainPrivateRoute exact path="/news/create" component={NewsCreate} />
    <MainPrivateRoute exact path="/user" component={UsersList} />
    <MainPrivateRoute exact path="/product/create" component={ProductCreate} />
    <MainPrivateRoute
      exact
      path="/product/:id/update"
      component={ProductCreate}
    />
    <MainPrivateRoute exact path="/user/create" component={UserCreate} />
    <MainPrivateRoute exact path="/user/:id/update" component={UserCreate} />
    <MainPrivateRoute exact path="/user/:id" component={UserDetail} />
    <MainPrivateRoute exact path="/origin" component={OriginAddress} />
    <MainPrivateRoute
      exact
      path="/origin/update"
      component={OriginAddressUpdate}
    />
    <MainPrivateRoute exact path="/statistic" component={StatisticPage} />
    <FullscreenPrivateRoute
      exact
      path="/statistic/order"
      component={OrderStatistic}
    />
    <FullscreenPrivateRoute
      exact
      path="/statistic/user"
      component={UserStatistic}
    />
    <FullscreenPrivateRoute
      exact
      path="/statistic/product"
      component={ProductStatistic}
    />
    <NoAuthRoute exact path="/login" component={LoginPage} />
    <NoAuthRoute exact path="/forgot-password" component={ForgotPassword} />
  </Switch>
)

export default Router
