import { makeStyles } from '@mui/styles'
import common from '@mui/material/colors/common'
import type { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: 1300,
  },
  toolbar: {
    minHeight: theme.spacing(8),
  },
  logo: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '100%',
    backgroundColor: common.white,
    '& img': {
      maxWidth: '100%',
    },
  },
  buttonPopover: {
    color: 'inherit',
    border: 0,
    cursor: 'pointer',
    margin: 0,
    display: 'inline-flex',
    outline: 0,
    padding: 0,
    position: 'relative',
    alignItems: 'center',
    userSelect: 'none',
    borderRadius: 0,
    verticalAlign: 'middle',
    '-moz-appearance': 'none',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '-webkit-appearance': 'none',
    '-webkit-tap-highlight-color': 'transparent',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    width: '200px',
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
    },
  },
  linearProgress: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}))
