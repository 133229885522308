/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import type { Reducer, Action, AnyAction } from 'redux'
import { ReactReduxContext } from 'react-redux'
import getInjector from 'store/reducerInjectors'

export interface Params<S = any, A extends Action = AnyAction> {
  /**
   * A key of the reducer
   */
  readonly key: string
  /**
   * A reducer that will be injected
   */
  readonly reducer: Reducer<S, A>
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useInjectReducer = <S extends any = any, A extends Action = AnyAction>({
  key,
  reducer,
}: Params<S, A>): void => {
  const context = React.useContext(ReactReduxContext)
  React.useEffect(() => {
    getInjector(context.store).injectReducer(key, reducer as Reducer)
  }, [key, reducer, context.store])
}

export { useInjectReducer }
