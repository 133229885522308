import React, { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook'
import CardTravel from '@mui/icons-material/CardTravel'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import AddShoppingCart from '@mui/icons-material/AddShoppingCart'
import Money from '@mui/icons-material/Money'
import GradeRounded from '@mui/icons-material/GradeRounded'
import Lock from '@mui/icons-material/Lock'
import SupervisedUserCircle from '@mui/icons-material/SupervisedUserCircle'
import BookTwoTone from '@mui/icons-material/BookTwoTone'
import Image from '@mui/icons-material/Image'
import AccountBoxOutlined from '@mui/icons-material/AccountBoxOutlined'
import HomeWork from '@mui/icons-material/HomeWork'
import Divider from '@mui/material/Divider'
import BarChartIcon from '@mui/icons-material/BarChart'

export interface MenuListProps {
  readonly user: UserSession
}

interface ListItemButtonProps {
  readonly label: string
  readonly icon: React.ReactNode
  readonly to: NavLinkProps['to']
}

const ListItemButton: FC<ListItemButtonProps> = ({ icon, label, to }) => {
  return (
    <ListItem
      disableGutters
      sx={{ display: 'flex', paddingTop: 0, paddingBottom: 0 }}
    >
      <Button
        component={NavLink}
        to={to}
        sx={{
          color: (theme) => theme.palette.text.secondary,
          width: '100%',
          padding: '10px 8px',
          fontWeight: 400,
          letterSpacing: 0,
          textTransform: 'none',
          justifyContent: 'flex-start',
          textAlign: 'start',
          display: 'flex',
          '&.active': {
            color: (theme) => theme.palette.secondary.main,
          },
          '& svg': {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
          },
        }}
        exact
      >
        {icon}
        <span style={{ fontWeight: 500 }}>{label}</span>
      </Button>
    </ListItem>
  )
}

const MenuList: FC<MenuListProps> = ({ user }: MenuListProps) => {
  const { role } = user
  return (
    <List>
      {role === 'admin' || role === 'super_admin' ? (
        <>
          <ListItemButton to="/" icon={<DonutLargeIcon />} label="Dashboard" />
          <Divider />
          <ListItemButton
            to="/product"
            icon={<AddShoppingCart />}
            label="Product"
          />
          <ListItemButton
            to="/packaging"
            icon={<AccountBoxOutlined />}
            label="Packaging"
          />
          <ListItemButton
            to="/courier"
            icon={<LaptopChromebookIcon />}
            label="Courier"
          />
          <ListItemButton
            to="/payment-method"
            icon={<Money />}
            label="Payment"
          />
          <ListItemButton to="/order" icon={<CardTravel />} label="Order" />
          <Divider />
          <ListItemButton
            to="/user"
            icon={<GroupOutlinedIcon />}
            label="User"
          />
          <ListItemButton
            to="/user-verification"
            icon={<GradeRounded />}
            label="User Verification"
          />
          <Divider />
          <ListItemButton to="/news" icon={<BookTwoTone />} label="News" />
          <ListItemButton to="/gallery" icon={<Image />} label="Gallery" />
          <ListItemButton
            to="/statistic"
            icon={<BarChartIcon />}
            label="Statistic"
          />
          <Divider />
          <ListItemButton
            to="/origin"
            icon={<HomeWork />}
            label="Shipping Origin"
          />
        </>
      ) : role === 'writer' ? (
        <>
          <ListItemButton to="/" icon={<DonutLargeIcon />} label="Dashboard" />
          <Divider />
          <ListItemButton to="/news" icon={<BookTwoTone />} label="News" />
          <ListItemButton to="/gallery" icon={<Image />} label="Gallery" />
          <Divider />
        </>
      ) : null}
      <ListItemButton
        to="/update-password"
        icon={<Lock />}
        label="Update Password"
      />
      <ListItemButton
        to="/user-profile"
        icon={<SupervisedUserCircle />}
        label="Update Profile"
      />
    </List>
  )
}

export default MenuList
