import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  root: {
    backgroundColor: '#1c2025',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))
