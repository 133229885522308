import { makeStyles } from '@mui/styles'
import type { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#1c2025',
    '& .imageCell': {
      width: '68px',
      flexGrow: 0,
      fontSize: 0,
      flexBasis: '68px',
      flexShrink: 0,
    },
    '& .rowLastBorderNone': {
      '&:last-child th, &:last-child td': {
        borderBottom: 0,
      },
    },
  },
  contentWrapper: {
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'hidden',
    paddingTop: '64px',
  },
  container1: {
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'hidden',
  },
  container2: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  container3: {
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: '#1c2025',
  },
}))
