import React, { FC, ReactNode } from 'react'
import useStyles from './useStyles'

export interface MainPageWrapperProps {
  readonly children: NonNullable<ReactNode>
}

const FullscreenPageWrapper: FC<MainPageWrapperProps> = ({
  children,
}: MainPageWrapperProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>{children}</div>
    </div>
  )
}

export default FullscreenPageWrapper
