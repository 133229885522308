import React, { FC, useState, useCallback, ReactNode } from 'react'
import Container from '@mui/material/Container'
import MainHeader from 'containers/MainHeader'
import SidebarMenu from 'containers/SidebarMenu'
import useStyles from './useStyles'

export interface MainPageWrapperProps {
  readonly children: NonNullable<ReactNode>
}

const MainPageWrapper: FC<MainPageWrapperProps> = ({
  children,
}: MainPageWrapperProps) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const toggle = useCallback(() => setOpen(!open), [open, setOpen])
  return (
    <div className={classes.root}>
      <MainHeader onNavigationClick={toggle} />
      <div className={classes.contentWrapper}>
        <div className={classes.container1}>
          <div className={classes.container2}>
            <div className={classes.container3}>
              <Container>{children}</Container>
            </div>
          </div>
        </div>
      </div>
      <SidebarMenu open={open} onClose={toggle} />
    </div>
  )
}

export default MainPageWrapper
