import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import './index.css'
import App from './App'
import ThemeProvider from './components/ThemeProvider'
import SessionProvider from './containers/SessionProvider'
import createStore from './store'
import history from '@history'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'

const store = createStore()

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={store}>
    <SessionProvider>
      <ConnectedRouter history={history}>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </SessionProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
