import React, { FC, useCallback } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Link from '@mui/material/Link'
import useStyles from './useStyles'
import { useSelector as useRootSelector } from 'react-redux'
import type { RootState } from 'store'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuList from './MenuList'
import UserProfile from './UserProfile'
import CopyrightIcon from '@mui/icons-material/Copyright'

export interface SidebarMenuProps {
  readonly open: boolean
  readonly onClose: () => void
}

const SidebarMenu: FC<SidebarMenuProps> = ({
  open,
  onClose,
}: SidebarMenuProps) => {
  const user = useRootSelector(({ session }: RootState) => session.user)
  const classes = useStyles()
  const closeDrawer = useCallback(
    (event: { type?: string; key?: string }) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return
      }
      onClose()
    },
    [onClose],
  )
  return (
    <SwipeableDrawer
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
        paperAnchorDockedLeft: classes.drawerPaperAnchorDockedLeft,
      }}
      open={open}
      onClose={closeDrawer}
      onOpen={closeDrawer}
    >
      <Box
        className={classes.scrollbarWrapper}
        onClick={closeDrawer}
        onKeyDown={closeDrawer}
      >
        <PerfectScrollbar>
          {user && (
            <>
              <UserProfile user={user} />
              <Box p={2}>
                <MenuList user={user} />
              </Box>
            </>
          )}
          <Divider />
          <Box p={2}>
            <Box
              p={2}
              borderRadius={4}
              style={{ backgroundColor: 'rgb(28, 32, 37)' }}
            >
              <Typography variant="h6" color="textPrimary">
                <CopyrightIcon style={{ fontSize: '12px' }} />{' '}
                {new Date().getFullYear()} | Powered by
              </Typography>
              <Link
                variant="subtitle1"
                color="secondary"
                underline="hover"
                href="https://asd-bakrie.com"
              >
                ASD Bakrie
              </Link>
            </Box>
          </Box>
        </PerfectScrollbar>
      </Box>
    </SwipeableDrawer>
  )
}

export default SidebarMenu
