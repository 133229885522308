import { makeStyles } from '@mui/styles'
import type { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: theme.spacing(32),
    height: '100%',
  },
  drawerPaperAnchorDockedLeft: {
    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
  },
  scrollbarWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))
