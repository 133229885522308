import { combineReducers, Reducer, ReducersMapObject } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import sessionReducer from '../containers/SessionProvider/reducer'
import type { SessionProviderState } from 'containers/SessionProvider/types'
import history from '@history'

export interface InjectedReducers {
  [k: string]: Reducer
}

export interface RootState {
  readonly router: RouterState
  readonly session: SessionProviderState
}

export type RootReducer = Reducer<RootState>

const createReducer = (
  injectedReducers: ReducersMapObject<Omit<RootState, 'router' | 'session'>>,
): RootReducer =>
  combineReducers({
    ...injectedReducers,
    router: connectRouter(history),
    session: sessionReducer,
  })

export default createReducer
